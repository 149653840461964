import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';

const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const Redirect = Loadable(lazy(() => import('views/pages/Redirect')));

// ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {
    return useRoutes([
        { path: '/', element: <PagesLanding /> }, 
        { path: '/404', element: <MaintenanceError /> },
        { path: '/:codigo_voucher', element: <Redirect /> },
    ]);
}
